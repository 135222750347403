<template>
  <div class="vg_wrapper">
    <el-card>
      <publicTips></publicTips>
      <div class="vd_search_group">
        <el-form label-width="120px" :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getSmplsNow()">
          <el-row>
            <el-col :md="8">
              <el-form-item label="委托单号:">
                <el-input size="small" v-model.trim="searchForm.requ_no" clearable placeholder="请填写委托单号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="样品编号:">
                <el-input size="small" v-model.trim="searchForm.smpl_no" clearable placeholder="请填写样品编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="样品名称:">
                <el-input size="small" v-model.trim="searchForm.smpl_name" clearable placeholder="请填写样品名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="单据状态:">
                <selectStatus ref="selectStatus" @selectStatusRow="getStatusVal"></selectStatus>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="样品类型:">
                <el-select v-model="searchForm.smpl_type" filterable placeholder="请选择样品类型" size="small" clearable>
                  <el-option v-for="item in smplType" :key="item.id" :label="item.label" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="开版师:">
                <el-select v-model="searchForm.smpl_stff_name" filterable placeholder="请选择开版师" size="small" clearable>
                  <el-option v-for="item in stffType" :key="item.user_id" :label="item.stff_name" :value="item.stff_name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <!--            <el-col :md="8">-->
            <!--              <el-form-item label="客户货号:">-->
            <!--                <el-input size="small" v-model.trim="searchForm.smpl_cust_no" clearable placeholder="请填写客户货号"></el-input>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->
            <el-col :md="8">
              <el-form-item label="更改号:">
                <el-input size="small" v-model.trim="searchForm.smpl_suffix" clearable placeholder="请填写更改号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="邮寄状态:">
                <el-select v-model="searchForm.smpl_ship_odd" filterable placeholder="请选择邮寄状态" size="small" clearable>
                  <el-option v-for="item in postList" :key="item.id" :label="item.lable" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="所属部门:">
                <el-select v-model="searchForm.belo_dept_id" filterable placeholder="请选择所属部门" size="small" clearable>
                  <el-option v-for="item in deptList" :key="item.id" :label="item.param1" :value="item.param2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="打样接收人:" prop="requ_receive">
                <el-select filterable v-model="searchForm.requ_receive" placeholder="请选择打样接收人" size="small" clearable>
                  <el-option v-for="item in stffType" :key="item.user_id" :label="item.stff_name" :value="item.stff_name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="实际交样时间:">
                <el-date-picker
                  size="small"
                  v-model="searchForm.timeValue"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :md="4">
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getSmplsNow()" class="vg_ml_16"> 查询 </el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <!-- <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button> -->
        <el-row>
          <el-col :md="20">
            <el-button type="danger" size="small" :disabled="!btn.delete" @click="doDelete()"
              ><i class="el-icon-delete"></i> 删除
            </el-button>
            <el-button type="info" size="small" @click="getDataPrompt()"><i class="el-icon-s-order"></i> 确认邮寄状态 </el-button>
          </el-col>
          <el-col :md="4">
            <span>打样总数量:{{ totalnum }}</span>
          </el-col>
        </el-row>

        <el-dialog :title="title" :visible.sync="smplFlag" width="45%">
          <SmplEditMail @MailCancel="MailCancel" @getSmpls="getSmpls" :smplPropList="smplPropList"></SmplEditMail>
        </el-dialog>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
            ref="multiTable"
            v-loading="loadFlag"
            @sort-change="sortChange"
            class="vg_cursor"
            :data="tableData"
            @selection-change="handleSelectionChange"
            @select="selectRows"
            @select-all="selectRows"
            @row-dblclick="dbClickJp"
            border
          >
            <el-table-column type="expand">
              <template v-slot="scope">
                <el-table :data="scope.row.rfid" style="width: 100%" v-if="scope.row.rfid">
                  <el-table-column label="开版状态" align="center">
                    <template v-slot="scope1">
                      <smplListRfid :numState="scope1.row.open_status"></smplListRfid>
                      <div v-if="scope1.row.procMap && scope1.row.procMap.open">第{{ scope1.row.procMap.open }}步</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="备料状态" align="center">
                    <template v-slot="scope1">
                      <smplListRfid :numState="scope1.row.stpr_status"></smplListRfid>
                      <div v-if="scope1.row.procMap && scope1.row.procMap.stpr">第{{ scope1.row.procMap.stpr }}步</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="复合状态" align="center">
                    <template v-slot="scope1">
                      <smplListRfid :numState="scope1.row.comp_status"></smplListRfid>
                      <div v-if="scope1.row.procMap && scope1.row.procMap.comp">第{{ scope1.row.procMap.comp }}步</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="激光状态" align="center">
                    <template v-slot="scope1">
                      <smplListRfid :numState="scope1.row.lase_status"></smplListRfid>
                      <div v-if="scope1.row.procMap && scope1.row.procMap.lase">第{{ scope1.row.procMap.lase }}步</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="印花状态" align="center">
                    <template v-slot="scope1">
                      <smplListRfid :numState="scope1.row.stam_status"></smplListRfid>
                      <div v-if="scope1.row.procMap && scope1.row.procMap.stam">第{{ scope1.row.procMap.stam }}步</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="电绣状态" align="center">
                    <template v-slot="scope1">
                      <smplListRfid :numState="scope1.row.embr_status"></smplListRfid>
                      <div v-if="scope1.row.procMap && scope1.row.procMap.embr">第{{ scope1.row.procMap.embr }}步</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="制卡状态" align="center">
                    <template v-slot="scope1">
                      <smplListRfid :numState="scope1.row.emcd_status"></smplListRfid>
                      <div v-if="scope1.row.procMap && scope1.row.procMap.emcd">第{{ scope1.row.procMap.emcd }}步</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="机缝状态" align="center">
                    <template v-slot="scope1">
                      <smplListRfid :numState="scope1.row.mase_status"></smplListRfid>
                      <div v-if="scope1.row.procMap && scope1.row.procMap.mase">第{{ scope1.row.procMap.mase }}步</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="手工状态" align="center">
                    <template v-slot="scope1">
                      <smplListRfid :numState="scope1.row.manu_status"></smplListRfid>
                      <div v-if="scope1.row.procMap && scope1.row.procMap.manu">第{{ scope1.row.procMap.manu }}步</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="检验状态" align="center">
                    <template v-slot="scope1">
                      <smplListRfid :numState="scope1.row.insp_status"></smplListRfid>
                      <div v-if="scope1.row.procMap && scope1.row.procMap.insp">第{{ scope1.row.procMap.insp }}步</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="报价状态" align="center">
                    <template v-slot="scope1">
                      <div v-if="scope1.row.pricer_status === 1" class="vd_tenpd">
                        <img src="@/assets/image/complate.png" alt="" class="vd_img" />
                        <span class="vd_complate">已完成</span>
                      </div>
                      <div class="vd_tenpd" v-else>
                        <img src="@/assets/image/notpass.png" alt="" class="vd_img" />
                        <div class="vd_notpass">未完成</div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="样品确认状态" align="center">
                    <template v-slot="scope1">
                      <div class="vd_tenpd" v-if="scope1.row.smpl_conf_status === 1">
                        <img src="@/assets/image/complate.png" alt="" class="vd_img" />
                        <span class="vd_complate">已确认</span>
                      </div>
                      <div class="vd_tenpd" v-else>
                        <img src="@/assets/image/notpass.png" alt="" class="vd_img" />
                        <div class="vd_notpass">未确认</div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="核价状态" align="center" class="vd_tenpd">
                    <template v-slot="scope1">
                      <div v-if="scope1.row.quoter_status === 1" class="vd_tenpd">
                        <img src="@/assets/image/complate.png" alt="" class="vd_img" />
                        <span class="vd_complate">已完成</span>
                      </div>
                      <div class="vd_tenpd" v-else>
                        <img src="@/assets/image/notpass.png" alt="" class="vd_img" />
                        <div class="vd_notpass">未完成</div>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <el-table :data="tableDataList" style="width: 100%" v-else></el-table>
              </template>
            </el-table-column>
            <el-table-column type="selection" width="48" align="center" />
            <el-table-column label="状态" width="100" align="center" prop="sample_status">
              <template v-slot="scope">
                <el-tag size="mini" v-if="scope.row.sample_status === 0" type="success">正常打样</el-tag>
                <el-tag size="mini" v-else-if="scope.row.sample_status === 1" type="danger">取消打样</el-tag>
                <el-tag size="mini" v-else type="primary">暂无</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="委托单号" prop="requ_no" />
            <el-table-column label="样品编号" prop="smpl_no" />
            <el-table-column label="更改号" prop="smpl_suffix" />
            <el-table-column label="样品名称" prop="smpl_name" show-overflow-tooltip>
              <template v-slot="scope">
                <span v-if="scope.row.smpl_name">
                  {{ scope.row.smpl_name }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="开版师" prop="smpl_stff_name" show-overflow-tooltip>
              <template v-slot="scope">
                <span v-if="scope.row.smpl_stff_name">
                  {{ scope.row.smpl_stff_name }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="样品类型" prop="smpl_type" show-overflow-tooltip>
              <template v-slot="scope">
                <span v-if="scope.row.smpl_type">
                  {{ scope.row.smpl_type | formatSmplType }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="样品间报价" prop="smpl_price">
              <template v-slot="scope">
                <span v-if="scope.row.smpl_price">
                  {{ scope.row.smpl_price | formaUnitPr }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="采购部核价" prop="smpl_verify_price">
              <template v-slot="scope">
                <span v-if="scope.row.smpl_verify_price">
                  {{ scope.row.smpl_verify_price | formaUnitP }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="打样数量" prop="smpl_num">
              <template v-slot="scope">
                <span v-if="scope.row.smpl_num">
                  {{ scope.row.smpl_num }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="打样部门" prop="belo_dept_name"></el-table-column>
            <el-table-column label="打样接收人" prop="requ_receive"></el-table-column>
            <el-table-column label="实际交样时间" prop="smpl_deil_time" show-overflow-tooltip>
              <template v-slot="scope">
                <span v-if="scope.row.smpl_deil_time">
                  {{ scope.row.smpl_deil_time | formatDate1 }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina" />
            <el-table-column label="创建时间" prop="create_time" :formatter="formatDate" show-overflow-tooltip />
            <el-table-column label="委托类型">
              <template v-slot="scope">
                <el-select size="mini" disabled filterable v-model="scope.row.smpl_quot" placeholder="请选择委托类型" clearable>
                  <el-option
                    v-for="item in scope.row.order_source === 0 ? smplQuotList : smplQuotList2"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="单据状态" align="center">
              <template v-slot="scope">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini"
                  >{{ helper.getStatusName(scope.row.status).name }}{{ scope.row.appr_num }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column label="邮寄状态" prop="smpl_ship_odd" align="center">
              <template v-slot="scope">
                <span v-if="scope.row.smpl_ship_odd === '1'">已完成</span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" prop="smpl_ship_odd" align="center">
              <template v-slot="scope">
                <el-button v-if="scope.row.status === 2" size="small" type="warning" @click="rework(scope.row)">重做 </el-button>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { smplAPI } from '@api/modules/smpl';
import pubPagination from '@/components/common/pubPagination';
import selectStatus from '@/views/component/selectStatus';
// import selectSectionType from '@/views/component/selectSectionType'
import smplListRfid from './TabChild/Componet/TabPanel/SmplListRfid.vue';
import publicTips from '@/views/component/publicTips';
import helper from '@assets/js/helper.js';
import { optnAPI } from '@api/modules/optn';
import SmplEditMail from './TabChild/SmplEditMail.vue';
import { userAPI } from '@api/modules/user';

export default {
  name: 'smplList',
  components: {
    pubPagination,
    selectStatus,
    smplListRfid,
    publicTips,
    SmplEditMail
  },
  data() {
    return {
      searchForm: {
        requ_no: null,
        smpl_name: null,
        smpl_no: null,
        smpl_type: null,
        status: null,
        belo_dept_id: null, //打样部门
        smpl_ship_odd: null,
        smpl_cust_no: null,
        smpl_stff_name: null,
        requ_receive: null,
        mtrl_sort: '',
        mtrl_field: '',
        timeValue: {
          startTime: null,
          endTime: null
        },
        smpl_suffix: null
      },
      postList: [
        {
          id: 0,
          lable: '已完成',
          value: '1'
        },
        {
          id: 1,
          lable: '未完成',
          value: '0'
        }
      ],
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      tableData1: [],
      tableDataList: [],
      multiSelection: [],
      btn: {},
      smplType: [
        { id: 1, label: '新款' },
        { id: 2, label: '老款' },
        { id: 3, label: '修改款' }
      ],
      loadFlag: true,
      isDyj: true,
      beloDeptId: null,
      smplFlag: false,
      title: '收件人邮箱确认',
      userInfo: {},
      deptList: [],
      smplPropList: [],
      stffType: [],
      totalnum: '',
      smplQuotList: [
        { id: 1, label: '打样并报价' },
        { id: 2, label: '试样报价' },
        { id: 3, label: '仅打样' },
        { id: 4, label: '估价' },
        { id: 5, label: '返工' }
      ],
      smplQuotList2: [
        { id: 1, label: '估价' },
        { id: 2, label: '仅报价' },
        { id: 3, label: '找样料' },
        { id: 4, label: '找用品' },
        { id: 5, label: '电绣/裁片' },
        { id: 6, label: '打样并报价' },
        { id: 7, label: '做文件' },
        { id: 8, label: '仅打样' }
      ]
    };
  },
  created() {
    this.getDepartment();
  },
  mounted() {
    this.searchForm.smpl_no = this.$route.query.smpl_no;
    this.searchForm.requ_no = this.$route.query.requ_no;
    this.searchForm.smpl_suffix = this.$route.query.smpl_suffix;
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/smpl_edit') {
        this.getSmpls();
      }
    }
  },
  filters: {
    // 样品类型转换
    formatSmplType(row) {
      if (row === 1) {
        return '新款';
      } else if (row === 2) {
        return '老款';
      } else if (row === 3) {
        return '修改款';
      }
    },
    //交样时间
    formatDate1(row) {
      return helper.toTimeDay(row);
    },
    formaUnitP(row) {
      return helper.haveFour(row);
    },
    // 物料单位转换
    formaUnitPr(row) {
      return helper.haveFour(row);
    }
  },
  methods: {
    rework(row) {
      this.$confirm('是否重做?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          const { perm_id } = this.$route.query;
          this.jump('/smpl_edit', { perm_id: perm_id, form_id: row.smpl_id, rework: true });
        })
        .catch(() => {});
    },
    initData() {
      this.getSmpls();
      this.getOptnByPermId();
      this.getStffType();
    },
    // copyMailODD(item){
    //    var cInput = document.createElement("input");
    //     cInput.value = item;
    //     document.body.appendChild(cInput);
    //     cInput.select(); // 选取文本框内容
    //     document.execCommand("copy");
    //     this.$message({
    //         type: "success",
    //         message: "已复制邮寄单号"
    //     });
    //     // 复制成功后再将构造的标签 移除
    //     document.body.removeChild(cInput);

    // },
    //判断是否 是DYJ员工
    getDepartment() {
      let userInfo = this.$cookies.get('userInfo');
      if (process.env.NODE_ENV === 'production') {
        if (userInfo.dept_id === 2 || userInfo.dept_id === 17) {
          this.searchForm.belo_dept_id = userInfo.dept_id + '';
          this.beloDeptId = userInfo.dept_id + '';
          this.isDyj = true;
        } else {
          this.searchForm.belo_dept_id = null;
          this.isDyj = false;
        }
      } else {
        if (userInfo.dept_id === 2 || userInfo.dept_id === 17) {
          this.searchForm.belo_dept_id = userInfo.dept_id + '';
          this.beloDeptId = userInfo.dept_id + '';
          this.isDyj = true;
        } else {
          this.searchForm.belo_dept_id = null;
          this.isDyj = false;
        }
      }
    },
    getOptnByPermId() {
      get(optnAPI.getOptnByPermId, { perm_id: 10009 })
        .then(res => {
          if (res.data.code === 0) {
            this.userInfo = this.$cookies.get('userInfo');
            let deptList = [{ param2: null, param1: '全部' }];
            let temp = res.data.data.form.optn_cntt_list;
            let temp1 = [];
            if (process.env.NODE_ENV === 'production') {
              if (this.userInfo.dept_id === 2) {
                temp.forEach(item => {
                  if (item.param2 === '2') {
                    temp1.push(item);
                  }
                });
                this.deptList = temp1;
              } else if (this.userInfo.dept_id === 17) {
                temp.forEach(item => {
                  if (item.param2 === '17') {
                    temp1.push(item);
                  }
                });
                this.deptList = temp1;
              } else {
                this.deptList = deptList.concat(temp);
              }
            } else {
              if (this.userInfo.dept_id === 2) {
                temp.forEach(item => {
                  if (item.param2 === '2') {
                    temp1.push(item);
                  }
                });
                this.deptList = temp1;
              } else if (this.userInfo.dept_id === 17) {
                temp.forEach(item => {
                  if (item.param2 === '17') {
                    temp1.push(item);
                  }
                });
                this.deptList = temp1;
              } else {
                this.deptList = deptList.concat(temp);
              }
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 获取产品信息
    getSmpls() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      get(smplAPI.getSmpls, {
        requ_no: this.searchForm.requ_no,
        smpl_no: this.searchForm.smpl_no,
        smpl_name: this.searchForm.smpl_name,
        smpl_ship_odd: this.searchForm.smpl_ship_odd,
        requ_receive: this.searchForm.requ_receive,
        smpl_type: this.searchForm.smpl_type,
        smpl_cust_no: this.searchForm.smpl_cust_no,
        smpl_stff_name: this.searchForm.smpl_stff_name,
        mtrl_sort: this.searchForm.mtrl_sort,
        mtrl_field: this.searchForm.mtrl_field,
        start_time: timeNewVal.startTime,
        end_time: timeNewVal.endTime,
        status: this.searchForm.status,
        page_no: this.currentPage,
        belo_dept_id: this.searchForm.belo_dept_id,
        smpl_suffix: this.searchForm.smpl_suffix
      })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            this.totalnum = res.data.data.smpl_num_total;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },

    // 获取委托人
    getStffType() {
      get(userAPI.getAllUsersV1)
        .then(res => {
          if (res.data.code === 0) {
            let stffType = res.data.data;
            let map = new Map();
            for (let item of stffType) {
              map.set(item.stff_name, item);
            }
            stffType = [...map.values()];
            this.stffType = stffType;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        requ_no: null,
        smpl_name: '',
        smpl_ship_odd: '',
        smpl_no: '',
        smpl_type: '',
        requ_receive: null,
        status: null,
        smpl_cust_no: null,
        smpl_stff_name: null,
        smpl_suffix: null
      };
      if (this.isDyj) {
        this.searchForm.belo_dept_id = this.beloDeptId;
      } else {
        this.searchForm.belo_dept_id = null;
      }
      this.loadFlag = true;
      this.$refs.selectStatus.changeValue();
      this.currentPage = 1;
      this.getSmpls();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 查询方法
    getSmplsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getSmpls();
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getSmpls();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 状态查询
    getStatusVal(val) {
      this.searchForm.status = val;
    },
    //删除提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status === 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: 'warning', message: '已生效的数据不可删除！' });
          this.$refs.multiTable.clearSelection();
        } else {
          this.mBox();
        }
      }
    },
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delRow();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
          this.$refs.multiTable.clearSelection();
        });
    },
    // 删除
    delRow() {
      const ids = [];
      this.multiSelection.map(item => {
        ids.push(item.smpl_id);
      });
      post(smplAPI.deleteSmplByIds, { smpl_id_list: ids })
        .then(res => {
          if (res.data.code === 0) {
            let mg = res.data.msg;
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            if (ids.length === this.tableData.length) {
              if (this.currentPage > 1) {
                this.currentPage = this.currentPage - 1;
                this.$refs.pubPagination.currentPage = this.currentPage;
              }
            }
            this.getSmpls();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.getSmpls();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //邮寄获取数据提示
    getDataPrompt() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status != 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: 'warning', message: '未生效的数据不可更改邮寄状态！' });
          this.$refs.multiTable.clearSelection();
        } else {
          this.MailImport();
        }
      }
    },
    // 邮寄状态确认弹框
    postConfirm() {
      this.$confirm('请确认勾选的样品工单已寄出？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.changePostStatus();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
          this.$refs.multiTable.clearSelection();
        });
    },
    // 更改样单邮寄状态
    changePostStatus() {
      const ids1 = [];
      this.multiSelection.map(item => {
        ids1.push(item.smpl_id);
      });
      const ids2 = [];
      this.multiSelection.map(item => {
        ids2.push(item.requ_id);
      });
      console.log('smpl_id列表', ids1);
      console.log('requ_id列表', ids2);
      post(smplAPI.addSmplMail, {
        requ_id_list: ids2,
        smpl_id_list: ids1
      })
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '更改成功'
            });
            this.getSmpls();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.getSmpls();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //邮寄弹窗
    MailImport() {
      this.smplPropList = this.multiSelection;
      this.smplFlag = true;
    },
    //邮寄取消
    MailCancel() {
      this.smplFlag = false;
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/smpl_edit', { perm_id: permId, form_id: row.smpl_id });
    },
    //查询范围
    getStatusVal2(val) {
      this.searchForm.belo_dept_id = val;
    },
    //升序 降序
    sortChange(val) {
      if (val.order == 'ascending') {
        this.searchForm.mtrl_sort = 'asc';
        this.searchForm.mtrl_field = val.prop;
      } else if (val.order == 'descending') {
        this.searchForm.mtrl_sort = 'desc';
        this.searchForm.mtrl_field = val.prop;
      } else {
        this.searchForm.mtrl_sort = '';
        this.searchForm.mtrl_field = '';
      }
      this.searchForm.page_no = 1;
      this.initData();

      // 升序 asc
      // 降序 desc
    }
  }
};
</script>

<style scoped lang="scss">
.vd_button_group {
  // width: 100vw;
  position: relative;
}

.vd_txta {
  position: absolute;
  left: 35%;
}

::v-deep .vd_tenpd {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vd_img {
  margin-top: 5px;
  width: 40px;
}

.vd_notpass {
  color: rgb(187, 187, 187);
}

.vd_pass {
  color: #188dff;
}

.vd_complate {
  color: #36ab60;
}

.vd_disable {
  color: #d81e06;
}

.vd_wei {
  color: #ff8511;
}

.reworkBtn {
  z-index: 999;
  background: #f3941c;
  border-color: #f3941c;
}
</style>
